import { Colors, Icons, View } from "@barscience/global-components";
import { CSSProperties } from "aphrodite";
import ProductCard from "./ProductCard";

const popupStyle: CSSProperties = {
  border: `1px solid ${Colors.neutral300}`,
  borderRadius: '8px',
  boxShadow: '0px 10px 20px 0px rgba(0, 0, 0, 0.10)',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '0px',
  maxWidth: 'min(600px, 100%)',
  padding: '16px',
}

type ProductsPopupProps = {
  onProductClick: () => void;
}

export default function ProductsPopup(props: ProductsPopupProps) {
  return (
    <View style={popupStyle}>
      <ProductCard name='Inventory' description='Track orders, invoices, and price changes across all items and vendors. Manage costs and reduce waste.' icon={Icons.Inventory} href='/products/inventory' onClick={props.onProductClick} />
      <ProductCard name='Scheduling' description='Build employee schedules, manage time off, track missed shifts, and manage labor costs.' icon={Icons.Schedule} isComingSoon href='/products/scheduling' onClick={props.onProductClick} disabled />
      <ProductCard name='Training' description='Train new employees, track progress, create custom courses, and provide continuous education to all of your staff.' icon={Icons.Training} isComingSoon href='/products/training' onClick={props.onProductClick} disabled />
    </View>
  );
}