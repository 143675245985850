import { View } from "@barscience/global-components";
import { CSSProperties } from "aphrodite";

type StandardPageWrapperProps = {
  children: React.ReactNode;
  style?: CSSProperties;
}

export default function StandardPageWrapper(props: StandardPageWrapperProps) {
  return (
    <>
      <View style={{ alignItems: 'center', flexGrow: 1, justifyContent: 'center', maxWidth: '100%', minHeight: 'max(600px, fit-content)', padding: '80px 40px', width: '100%', '@media (max-width: 767px)': { padding: '40px 20px' }, ...props.style }}>
        {props.children}
      </View>
    </>
  );
}