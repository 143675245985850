import { Button, Icon, Icons, Logo, View, useAuthState } from "@barscience/global-components";
import { CSSProperties, StyleSheet, css } from "aphrodite";
import HeaderLink from "./HeaderLink";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { usePopper } from "react-popper";
import ProductsPopup from "./ProductsPopup";

const customStyles = StyleSheet.create({
  headerWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
});

const headerStyle: CSSProperties = {
  alignItems: 'center',
  flexDirection: 'row',
  height: '120px',
  justifyContent: 'space-between',
  maxWidth: '1800px',
  padding: '0px 100px',
  width: '100%',
  '@media (min-width: 768px) and (max-width: 1151px)': {
    padding: '0px 50px',
  },
  '@media (max-width: 767px)': {
    padding: '0px 24px',
  },
}

export default function Header() {
  const { state } = useAuthState();

  /* Products Popup */
  const [isProductsPopupOpen, setIsProductsPopupOpen] = useState<boolean>(false);
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    strategy: 'fixed',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['bottom-end'],
        },
      },
      {
        name: 'hide',
      },
    ],
  });

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      const target = e.target as Node;

      if (referenceElement && (!referenceElement.contains(target) && !popperElement?.contains(target))) {
        setIsProductsPopupOpen(false);
      }
    }

    document.addEventListener('click', handleClick, true);

    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [referenceElement, popperElement]);

  const host = document.querySelector('body');
  if (!host) {
    return null;
  }

  return (
    <>
      <nav className={css(customStyles.headerWrapper)}>
        <View style={headerStyle}>
          <Link to='/'>
            <Logo />
          </Link>

          <View style={{ alignItems: 'center', flexDirection: 'row', gap: '60px', '@media (max-width: 1310px)': { display: 'none' } }}>
            <HeaderLink label='Products' action={() => { setIsProductsPopupOpen(!isProductsPopupOpen); }} role='button' ref={setReferenceElement} />
            <HeaderLink label='Company' href='/company/about' role='link' />
            <HeaderLink label='Support' href='https://support.barscience.us' role='link' />
          </View>

          <View style={{ flexDirection: 'row', gap: '24px', '@media (max-width: 1310px)': { display: 'none' } }}>
            {state.user ?
              <>
                <Button label='My Products' variant='primary' role='link' href={getStartLink()} rightIcon={Icons.ArrowRight} style={{ maxWidth: '', width: 'fit-content' }} />
              </>
              :
              <>
                <Button label='Login' variant='secondary' role='link' href={getLoginLink()} />
                <Button label='Sign Up' variant='secondary' role='link' href='/signup' />
                <Button label='Explore Products' variant='primary' role='link' href='/products' rightIcon={Icons.ArrowRight} style={{ maxWidth: '', width: 'fit-content' }} />
              </>
            }
          </View>

          <Icon icon={Icons.Bars} size='large' style={{ '@media (min-width: 1311px)': { display: 'none' } }} />
        </View>
      </nav>

      {isProductsPopupOpen && (
        ReactDOM.createPortal(
          <div ref={setPopperElement} style={{ ...styles.popper, zIndex: 10, maxWidth: 'min(600px, 100%)', backgroundColor: '#ffffff', borderRadius: '8px' }} {...attributes.popper}>
            <ProductsPopup onProductClick={() => { setIsProductsPopupOpen(false); }} />
          </div>, host)
      )}
    </>
  );
}

const getLoginLink = () => {
  if (window.location.href.includes('localhost')) {
    return 'http://localhost:6002/login';
  } else if (window.location.href.includes('dev')) {
    return 'https://auth-dev.barscience.us/login';
  } else {
    return 'https://auth.barscience.us/login';
  }
}

const getStartLink = () => {
  if (window.location.href.includes('localhost')) {
    return 'http://localhost:6007';
  } else if (window.location.href.includes('dev')) {
    return 'https://start-dev.barscience.us';
  } else {
    return 'https://start.barscience.us';
  }
}