import { Colors, StyledHeading } from "@barscience/global-components";
import { StyleSheet, css } from "aphrodite";
import { forwardRef } from "react";
import { Link } from "react-router-dom";

type HeaderLinkProps = {
  label: string;
  action: () => void;
  role: 'button';
} | {
  label: string;
  href: string;
  role: 'link';
  target?: React.HTMLAttributeAnchorTarget;
}

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    background: 'none',
    border: 'none',
    color: 'inherit',
    cursor: 'pointer',
    display: 'inline-flex',
    justifyContent: 'center',
    padding: '8px 4px',
    textDecoration: 'none',
    ':hover': {
      borderBottom: `3px solid ${Colors.primary500}`,
      color: Colors.primary500,
      paddingBottom: '5px',
    },
    ':active': {
      borderBottom: `2px solid ${Colors.primary700}`,
      color: Colors.primary700,
      paddingBottom: '6px',
    },
  },
  link: {
    alignItems: 'center',
    color: 'inherit',
    cursor: 'pointer',
    display: 'inline-flex',
    justifyContent: 'center',
    padding: '8px 4px',
    textDecoration: 'none',
    ':hover': {
      borderBottom: `3px solid ${Colors.primary500}`,
      color: Colors.primary500,
      paddingBottom: '5px',
    },
    ':active': {
      borderBottom: `2px solid ${Colors.primary700}`,
      color: Colors.primary700,
      paddingBottom: '6px',
    },
  },
});

const HeaderLink = forwardRef(function (props: HeaderLinkProps, ref: React.Ref<HTMLButtonElement>) {
  if (props.role === 'button') {
    return (
      <button className={css(styles.button)} onClick={() => { props.action(); }} ref={ref}>
        <StyledHeading tag='h6'>{props.label}</StyledHeading>
      </button>
    );
  } else if (props.role === 'link') {
    const isExternal = props.href.startsWith('http');

    return (
      <Link target={props.target || (isExternal ? '_blank' : '_self')} to={props.href} className={css(styles.link)}>
        <StyledHeading tag='h6'>{props.label}</StyledHeading>
      </Link>
    );
  }

  return <></>;
});

export default HeaderLink;