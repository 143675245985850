import { Button, Card, Colors, Icons, Link, StyledDisplay, StyledHeading, StyledParagraph, StyledSubheading, View } from "@barscience/global-components";
import { StyleSheet, css } from "aphrodite";
import ProductFeatureCard from "../../components/products/ProductFeatureCard";
import { useState } from "react";
import StandardPageWrapper from "../../components/layout/StandardPageWrapper";

const styles = StyleSheet.create({
  sectionHeading: {
    color: Colors.neutral700,
    fontFamily: '"Sharp Sans", "Open Sans", sans-serif',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '24px',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  productImage: {
    border: `1px solid ${Colors.neutral300}`,
    borderRadius: '4px',
    maxWidth: '100%',
    width: '100%',
  },
});

enum OrderingCardID {
  BuildOrders,
  ReconcileInvoices,
  TrackSpending,
}

enum ProductManagementID {
  ItemTracking,
  RecipeTracking,
  CustomItemOrganization,
}

enum CostControlID {
  ItemCounts,
  PCReporting,
  Shrinkage,
}

export default function Inventory() {
  const [selectedOrderingCard, setSelectedOrderingCard] = useState<OrderingCardID>(OrderingCardID.BuildOrders);
  const [selectedProductManagementCard, setSelectedProductManagementCard] = useState<ProductManagementID>(ProductManagementID.ItemTracking);
  const [selectedCostControlCard, setSelectedCostControlCard] = useState<CostControlID>(CostControlID.ItemCounts);

  return (
    <>
      <StandardPageWrapper style={{ padding: '0', '@media (max-width: 767px)': { padding: '0' } }}>
        <View style={{ alignItems: 'center', gap: '48px', paddingBottom: '100px', width: '100%' }}>

          <View style={{ alignItems: 'center', background: 'linear-gradient(180deg, #FFF 23.96%, #F0FDF0 99.99%, #FFF 100%)', width: '100%' }}>
            <View style={{ alignItems: 'center', flexDirection: 'row', gap: '48px', justifyContent: 'space-between', maxWidth: '1800px', padding: '100px 140px', width: '100%', '@media (max-width: 1385px)': { flexDirection: 'column-reverse' }, '@media (max-width: 767px)': { padding: '40px 0px' } }}>
              <View style={{ gap: '24px', maxWidth: '100%', minWidth: 'min(526px, 100%)', padding: '24px', '@media (max-width: 1385px)': { alignItems: 'center' } }}>
                <StyledDisplay size='small' weight='bold' style={{ '@media (max-width: 1385px)': { textAlign: 'center' } }}>Smart Inventory Management</StyledDisplay>
                <StyledSubheading style={{ color: Colors.neutral700, '@media (max-width: 1385px)': { textAlign: 'center' } }} >Take control of your inventory like never before.</StyledSubheading>
                <Button label='Start My Free Trial' variant='primary' role='button' action={() => { const pricing = document.getElementById('pricing'); pricing?.scrollIntoView({ behavior: 'smooth', block: 'start' }); }} rightIcon={Icons.ArrowRight} style={{ maxWidth: '', width: 'fit-content' }} />
              </View>
              <img src='../illustrations/checking-inventory.svg' alt='A woman with an inventory checklist' style={{ flexShrink: 1, maxWidth: 'min(600px, 90%)', minWidth: '300px', width: '100%' }} />
            </View>
          </View>

          <View style={{ alignItems: 'center', gap: '16px', padding: '24px', width: '100%' }}>
            <StyledHeading tag='h1' weight='bold'>Features</StyledHeading>
            <StyledSubheading style={{ textAlign: 'center' }}>Powerful inventory management tools for bar and restaurant success.</StyledSubheading>
          </View>

          <View style={{ alignItems: 'center', backgroundColor: Colors.neutral50, gap: '48px', padding: '48px 0px', width: '100%' }}>
            <p className={css(styles.sectionHeading)}>Streamlined Ordering and Receiving</p>
            <StyledHeading tag='h3' style={{ maxWidth: 'min(800px, 80%)', textAlign: 'center' }}>Effortlessly manage your inventory, orders, and invoices in one unified platform.</StyledHeading>

            <View style={{ alignItems: 'center', flexDirection: 'row', gap: '32px', justifyContent: 'center', padding: '0px 120px', '@media (max-width: 2000px)': { padding: '0px 40px' }, '@media (max-width: 1151px)': { flexDirection: 'column-reverse' } }}>
              <View style={{ gap: '32px', maxWidth: '450px', '@media (max-width: 1151px)': { maxWidth: '1300px', width: '100%' } }}>
                <ProductFeatureCard<OrderingCardID> title='Build Orders' description='Create and manage orders for all vendors. View estimated costs and identify price changes. With streamlined ordering workflows, you can optimize purchasing decisions, minimize excess inventory, and boost your bottom line.' id={OrderingCardID.BuildOrders} onSelect={(id) => { setSelectedOrderingCard(id); }} isActive={selectedOrderingCard === OrderingCardID.BuildOrders} />
                <ProductFeatureCard<OrderingCardID> title='Reconcile Invoices' description='Create invoices automatically based on ordered items. Seamlessly reconcile invoices with vendor statements to ensure accurate pricing and item receipt.' id={OrderingCardID.ReconcileInvoices} onSelect={(id) => { setSelectedOrderingCard(id); }} isActive={selectedOrderingCard === OrderingCardID.ReconcileInvoices} />
                <ProductFeatureCard<OrderingCardID> title='Track Spending' description='View total spending by category and vendor for an inventory period. With comprehensive spending data at your fingertips, you can stay in control of your budget and identify cost-saving opportunities.' id={OrderingCardID.TrackSpending} onSelect={(id) => { setSelectedOrderingCard(id); }} isActive={selectedOrderingCard === OrderingCardID.TrackSpending} />
              </View>

              <View style={{ maxWidth: '1150px', minWidth: '1000px', '@media (max-width: 1000px)': { minWidth: '0', width: '100%' } }}>
                <img src='../images/screen-placeholder.png' alt='A screen placeholder' className={css(styles.productImage)} />
              </View>
            </View>
          </View>

          <View style={{ alignItems: 'center', gap: '48px', padding: '48px 0px', width: '100%' }}>
            <p className={css(styles.sectionHeading)}>Centralized Product Management</p>
            <StyledHeading tag='h3' style={{ maxWidth: 'min(800px, 80%)', textAlign: 'center' }}>Manage all vendor and recipe items in the same place.</StyledHeading>

            <View style={{ alignItems: 'center', flexDirection: 'row', gap: '32px', justifyContent: 'center', padding: '0px 120px', '@media (max-width: 2000px)': { padding: '0px 40px' }, '@media (max-width: 1151px)': { flexDirection: 'column' } }}>
              <View style={{ maxWidth: '1150px', minWidth: '1000px', '@media (max-width: 1000px)': { minWidth: '0', width: '100%' } }}>
                <img src='../images/screen-placeholder.png' alt='A screen placeholder' className={css(styles.productImage)} />
              </View>

              <View style={{ gap: '32px', maxWidth: '450px', '@media (max-width: 1151px)': { maxWidth: '1300px', width: '100%' } }}>
                <ProductFeatureCard<ProductManagementID> title='Item Tracking' description='Items purchased from all vendors, managed in one system.' id={ProductManagementID.ItemTracking} onSelect={(id) => { setSelectedProductManagementCard(id); }} isActive={selectedProductManagementCard === ProductManagementID.ItemTracking} />
                <ProductFeatureCard<ProductManagementID> title='Recipe Tracking' description='Create a centralized recipe book to streamline item prep and menu planning. View accurate recipe costs and set optimal menu prices.' id={ProductManagementID.RecipeTracking} onSelect={(id) => { setSelectedProductManagementCard(id); }} isActive={selectedProductManagementCard === ProductManagementID.RecipeTracking} />
                <ProductFeatureCard<ProductManagementID> title='Custom Item Organization' description='Assign vendor items and recipes to physical locations in your store. Create custom views to simplify ordering items and performing item counts.' id={ProductManagementID.CustomItemOrganization} onSelect={(id) => { setSelectedProductManagementCard(id); }} isActive={selectedProductManagementCard === ProductManagementID.CustomItemOrganization} />
              </View>
            </View>
          </View>

          <View style={{ alignItems: 'center', backgroundColor: Colors.secondary50, gap: '48px', padding: '48px 0px', width: '100%' }}>
            <p className={css(styles.sectionHeading)}>Enhanced Cost Control</p>
            <StyledHeading tag='h3' style={{ maxWidth: 'min(800px, 80%)', textAlign: 'center' }}>Identify waste, reduce costs, and maximize your bottom line.</StyledHeading>

            <View style={{ alignItems: 'center', flexDirection: 'row', gap: '32px', justifyContent: 'center', padding: '0px 120px', '@media (max-width: 2000px)': { padding: '0px 40px' }, '@media (max-width: 1151px)': { flexDirection: 'column-reverse' } }}>
              <View style={{ gap: '32px', maxWidth: '450px', '@media (max-width: 1151px)': { maxWidth: '1300px', width: '100%' } }}>
                <ProductFeatureCard<CostControlID> title='Perform Item Counts' description='Perform item counts at the end of an inventory period to see an accurate snapshot of what you have on hand.' id={CostControlID.ItemCounts} onSelect={(id) => { setSelectedCostControlCard(id); }} isActive={selectedCostControlCard === CostControlID.ItemCounts} />
                <ProductFeatureCard<CostControlID> title='% Cost Reporting' description='Generate a Percent Cost report to identify areas of high cost and improve margins.' id={CostControlID.PCReporting} onSelect={(id) => { setSelectedCostControlCard(id); }} isActive={selectedCostControlCard === CostControlID.PCReporting} />
                <ProductFeatureCard<CostControlID> title='Combat Shrinkage and Theft' description='Generate a shrinkage report to see what items are unaccounted for---whether its waste or theft, you’ll be able to identify what’s missing.' id={CostControlID.Shrinkage} onSelect={(id) => { setSelectedCostControlCard(id); }} isActive={selectedCostControlCard === CostControlID.Shrinkage} />
              </View>

              <View style={{ maxWidth: '1150px', minWidth: '1000px', '@media (max-width: 1000px)': { minWidth: '0', width: '100%' } }}>
                <img src='../images/screen-placeholder.png' alt='A screen placeholder' className={css(styles.productImage)} />
              </View>
            </View>
          </View>

          <View style={{ alignItems: 'center', gap: '48px', maxWidth: '100%', padding: '0px 140px', '@media (max-width: 767px)': { padding: '0px 16px' } }} id='pricing'>
            <View style={{ alignItems: 'center', gap: '16px' }}>
              <StyledHeading tag='h1' weight='bold'>Pricing</StyledHeading>
              <StyledSubheading>Powerful tools, simple pricing.</StyledSubheading>
            </View>

            <Card size='medium' style={{ maxWidth: 'min(600px, calc(100% - 40px))', width: '100%', padding: '32px 48px', '@media (max-width: 767px)': { padding: '16px' } }}>
              <View style={{ alignItems: 'center', gap: '60px', justifyContent: 'center', width: '100%' }}>
                <View style={{ alignItems: 'center', gap: '24px' }}>
                  <StyledHeading tag='h5'>Standard</StyledHeading>
                  <StyledSubheading style={{ color: Colors.neutral700, textAlign: 'center' }}>All of our powerful inventory features, available at one low monthly price.</StyledSubheading>
                  <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px' }}>
                    <StyledDisplay size='small' weight='bold'>$39</StyledDisplay>
                    <StyledHeading tag='h5'>/ month</StyledHeading>
                  </View>
                  <View style={{ alignItems: 'center', gap: '8px' }}>
                    <StyledHeading tag='h6' weight='semi-bold'>This plan includes:</StyledHeading>
                    <StyledParagraph>All inventory features</StyledParagraph>
                    <StyledParagraph>Up to 50 users</StyledParagraph>
                  </View>
                </View>
                <View style={{ alignItems: 'center', gap: '24px' }}>
                  <StyledHeading tag='h6' style={{ textAlign: 'center' }}>Get started today with a 14-day free trial!</StyledHeading>
                  <Button label='Start Free Trial' variant='primary' role='link' href='/signup' />
                </View>
              </View>
            </Card>
          </View>

          <View style={{ alignItems: 'center', gap: '48px', justifyContent: 'center', padding: '48px 140px 0px 140px', '@media (max-width: 767px)': { padding: '48px 40px 0px 40px' } }}>
            <StyledHeading tag='h1' weight='bold' id='pricing'>FAQs</StyledHeading>
            <View style={{ alignItems: 'center', gap: '24px', maxWidth: '900px', width: '100%', '@media (max-width: 767px)': { maxWidth: '100%' } }}>
              <Card size='medium' style={{ width: '100%' }}>
                <View style={{ gap: '8px' }}>
                  <StyledParagraph bold>What is the free trial period?</StyledParagraph>
                  <StyledParagraph>You can access all of the Inventory features for free during your 14-day free trial period. At the end of the trial period, you will automatically be converted to the Standard plan and charged on a monthly basis. You can cancel your subscription anytime before the end of your free trial to avoid being charged.</StyledParagraph>
                </View>
              </Card>
              <Card size='medium' style={{ width: '100%' }}>
                <View style={{ gap: '8px' }}>
                  <StyledParagraph bold>Do you have plans for more than 50 users?</StyledParagraph>
                  <StyledParagraph>Yes! If you need more than 50 users, please <Link href='https://support.barscience.us' target='_blank'>reach out to our support team</Link> to discuss pricing options.</StyledParagraph>
                </View>
              </Card>
              <Card size='medium' style={{ width: '100%' }}>
                <View style={{ gap: '8px' }}>
                  <StyledParagraph bold>What are my payment options?</StyledParagraph>
                  <StyledParagraph>We accept payment by credit card (American Express, Discover, MasterCard, or Visa) or Google Pay.</StyledParagraph>
                </View>
              </Card>
              <Card size='medium' style={{ width: '100%' }}>
                <View style={{ gap: '8px' }}>
                  <StyledParagraph bold>Does the Standard plan support multiple locations?</StyledParagraph>
                  <StyledParagraph>No. Our Inventory product is designed to support only one location. If your company has multiple stores, you will need to sign up each store individually.</StyledParagraph>
                </View>
              </Card>
            </View>
          </View>

        </View>
      </StandardPageWrapper>
    </>
  );
}