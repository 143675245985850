import { useAlertState, View } from '@barscience/global-components';
import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Header from './components/header';
import Home from './pages/home/Home';
import Footer from './components/footer';
import Inventory from './pages/products/Inventory';
import Signup from './pages/signup/Signup';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const { alerts } = useAlertState();

  return (
    <>
      {alerts.length > 0 &&
        <View style={{ bottom: '24px', gap: '16px', left: '24px', position: 'fixed', zIndex: 1 }}>
          {alerts.map((a) => {
            return (
              <React.Fragment key={a.id}>
                {a.alert}
              </React.Fragment>
            );
          })}
        </View>
      }
      <View style={{ height: '100vh', overflowX: 'hidden', overflowY: 'auto', width: '100vw'}}>
        <ScrollToTop />
        
        <Header />

        <Routes>
          <Route path='/' element={<Home />} />

          <Route path='/signup' element={<Signup />} />

          <Route path='/products/inventory' element={<Inventory />} />
        </Routes>

        <Footer />
      </View>
    </>
  );
}

export default App;
