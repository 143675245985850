import { Card, Colors, Icon, Icons, StyledHeading, StyledParagraph, View } from "@barscience/global-components";

type ProductFeatureCardProps<T> = {
  title: string;
  description: string;
  isActive: boolean;
  onSelect: (id: T) => void;
  id: T;
}

export default function ProductFeatureCard<T>(props: ProductFeatureCardProps<T>) {
  return (
    <>
      <div onMouseEnter={() => { props.onSelect(props.id); }} onClick={() => { props.onSelect(props.id); }}>
        <Card size='medium' style={{ flexGrow: 1, flexShrink: 1, padding: '32px', ...(props.isActive ? { backgroundColor: Colors.primary50, border: `2px solid ${Colors.primary500}` } : {}) }}>
          <View style={{ gap: '16px' }}>
            <View style={{ flexDirection: 'row', gap: '24px', justifyContent: 'space-between' }}>
              <StyledHeading tag='h5' weight='bold'>{props.title}</StyledHeading>
              {props.isActive ?
                <Icon icon={Icons.Minus} size='medium' style={{ '@media (min-width: 1152px)': { display: 'none' } }} key='icon-active' />
                :
                <Icon icon={Icons.Plus} size='medium' style={{ '@media (min-width: 1152px)': { display: 'none' } }} key='icon-inactive' />
              }
            </View>
            <StyledParagraph style={{ color: Colors.neutral700, ...(props.isActive ? {} : { '@media (max-width: 1151px)': { display: 'none' } }) }}>{props.description}</StyledParagraph>
          </View>
        </Card>
      </div>
    </>
  );
}