import { Colors, Icon, Icons, StyledParagraph, View } from "@barscience/global-components";
import { CSSProperties, StyleSheet, css } from "aphrodite";
import { Link } from "react-router-dom";

const styles = StyleSheet.create({
  comingSoon: {
    alignItems: 'center',
    backgroundColor: Colors.primary500,
    borderRadius: '4px',
    color: '#ffffff',
    display: 'flex',
    fontFamily: '"Sharp Sans", "Open Sans", sans-serif',
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '14px',
    padding: '4px 8px',
    textTransform: 'uppercase',
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
  }
});

const containerStyle: CSSProperties = {
  backgroundColor: '#ffffff',
  borderRadius: '4px',
  padding: '16px',
  ':hover': {
    backgroundColor: Colors.neutral100,
  },
  ':active': {
    backgroundColor: Colors.neutral300,
  },
}

const containerDisabledStyle: CSSProperties = {
  cursor: 'default',
  ':hover': {
    backgroundColor: '#ffffff',
  },
  ':active': {
    backgroundColor: '#ffffff',
  },
}

type ProductCardProps = {
  name: string;
  description: string;
  icon: Icons;
  isComingSoon?: boolean;
  href: string;
  onClick: () => void;
  disabled?: boolean;
}

export default function ProductCard(props: ProductCardProps) {
  if (props.disabled) {
    return (
      <View style={{ ...containerStyle, ...containerDisabledStyle }}>
        <View style={{ flexDirection: 'row', gap: '16px' }}>
          <View style={{ flexDirection: 'row', gap: '12px' }}>
            <Icon icon={props.icon} size='medium' />
            <StyledParagraph bold>{props.name}</StyledParagraph>
          </View>

          {props.isComingSoon &&
            <span className={css(styles.comingSoon)}>Coming Soon</span>
          }
        </View>
        <View style={{ paddingLeft: '36px' }}>
          <StyledParagraph>{props.description}</StyledParagraph>
        </View>
      </View>
    );
  }

  return (
    <Link to={props.href} className={css(styles.link)} onClick={props.onClick}>
      <View style={containerStyle}>
        <View style={{ flexDirection: 'row', gap: '16px' }}>
          <View style={{ flexDirection: 'row', gap: '12px' }}>
            <Icon icon={props.icon} size='medium' />
            <StyledParagraph bold>{props.name}</StyledParagraph>
          </View>

          {props.isComingSoon &&
            <span className={css(styles.comingSoon)}>Coming Soon</span>
          }
        </View>
        <View style={{ paddingLeft: '36px' }}>
          <StyledParagraph>{props.description}</StyledParagraph>
        </View>
      </View>
    </Link>
  );
}