import { Colors, Icon, Icons, Link, Logo, StyledFooter, StyledParagraph, View } from "@barscience/global-components";
import { CSSProperties, StyleSheet, css } from "aphrodite";

const styles = StyleSheet.create({
  footerWrapper: {
    alignItems: 'center',
    backgroundColor: Colors.neutral100,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
});

const footerStyle: CSSProperties = {
  alignItems: 'center',
  justifyContent: 'center',
  maxWidth: '1800px',
  padding: '72px 100px ',
  width: '100%',
  '@media (min-width: 768px) and (max-width: 1151px)': {
    padding: '72px 50px',
  },
  '@media (max-width: 767px)': {
    padding: '40px 24px',
  }
}

export default function Footer() {
  return (
    <>
      <footer className={css(styles.footerWrapper)}>
        <View style={footerStyle}>
          <View style={{ gap: '80px', width: '100%' }}>
            <View style={{ flexDirection: 'row', gap: '40px', justifyContent: 'space-between', '@media (max-width: 650px)': { alignItems: 'center', flexDirection: 'column', justifyContent: 'center' } }}>
              <Logo />
              <View style={{ flexDirection: 'row', gap: '72px', '@media (max-width: 650px)': { flexWrap: 'wrap', gap: '32px' } }}>
                <View style={{ gap: '8px' }}>
                  <StyledParagraph bold>Products</StyledParagraph>
                  <Link href='/products/inventory' linkStyle={{ color: 'inherit' }}><StyledFooter>Inventory</StyledFooter></Link>
                </View>
                <View style={{ gap: '8px' }}>
                  <StyledParagraph bold>Resources</StyledParagraph>
                  <Link href='https://support.barscience.us' linkStyle={{ color: 'inherit' }}><StyledFooter>Help Center</StyledFooter></Link>
                  <Link href='https://support.barscience.us/community' linkStyle={{ color: 'inherit' }}><StyledFooter>Community</StyledFooter></Link>
                </View>
                <View style={{ gap: '8px' }}>
                  <StyledParagraph bold>Company</StyledParagraph>
                  <Link href='/company/about' linkStyle={{ color: 'inherit' }}><StyledFooter>About</StyledFooter></Link>
                  <Link href='/company/team' linkStyle={{ color: 'inherit' }}><StyledFooter>Team</StyledFooter></Link>
                </View>
              </View>
            </View>
            <View style={{ alignItems: 'center', flexDirection: 'row', gap: '40px', justifyContent: 'space-between', '@media (max-width: 650px)': { flexDirection: 'column-reverse' } }}>
              <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }}>
                <Icon icon={Icons.Copyright} size='medium' />
                <StyledParagraph bold style={{ color: Colors.neutral700 }}>Copyright 2023 Bar Science</StyledParagraph>
              </View>
              <View style={{ flexDirection: 'row', gap: '24px', '@media (max-width: 650px)': { flexWrap: 'wrap' } }}>
                <Link href='' linkStyle={{ color: Colors.neutral700 }}>Terms of Service</Link>
                <Link href='' linkStyle={{ color: Colors.neutral700 }}>Privacy Policy</Link>
              </View>
            </View>
          </View>
        </View>
      </footer>
    </>
  );
}