import { useMutation } from "@apollo/client";
import { Button, Card, Colors, Form, Link, StyledHeading, StyledParagraph, TextField, View, useForm } from "@barscience/global-components";
import gql from "graphql-tag";
import StandardPageWrapper from "../../components/layout/StandardPageWrapper";

/* Request Invite Mutation */
const REQUEST_INVITE = gql`
mutation createOwnUserInvite($email: String!, $firstName: String!, $lastName: String!) {
  success: inviteUser(email: $email, firstName: $firstName, lastName: $lastName)
}
`;

type RequestInviteResponse = {
  success: boolean;
}

type SignupFormInput = {
  firstName: string;
  lastName: string;
  email: string;
}

export default function Signup() {
  const [requestInvite, { data: inviteData, loading: inviteIsLoading, error: inviteError }] = useMutation<RequestInviteResponse>(REQUEST_INVITE);

  const handleSignup = async (values: SignupFormInput) => {
    if (!values.email || !values.firstName || !values.lastName) return;

    await requestInvite({
      variables: {
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
      },
    });
  }

  const signupForm = useForm<SignupFormInput>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
    },
    onSubmit: handleSignup,
  });

  return (
    <>
      <StandardPageWrapper>
        <Card size='medium' style={{ maxWidth: 'min(500px, 100%)', minHeight: 'fit-content', padding: '32px' }}>
          {inviteData?.success ?
            <View style={{ alignItems: 'center', gap: '16px' }}>
              <img src='illustrations/check-email.svg' alt='An opened letter with a checkmark' style={{ maxWidth: '300px' }} />
              <StyledHeading tag='h4' weight='bold' style={{ marginTop: '24px' }}>We've sent you a verification email!</StyledHeading>
              <StyledParagraph style={{ textAlign: 'center' }}>Please click on the link in your email to finish setting up your account.</StyledParagraph>
            </View>
            :
            <Form handleSubmit={signupForm.handleSubmit}>
              <View style={{ gap: '24px' }}>
                <View style={{ gap: '8px', marginBottom: '12px' }}>
                  <StyledHeading tag='h4' weight='bold'>Let's get started!</StyledHeading>
                  <StyledParagraph style={{ color: Colors.neutral700 }}>Enter your information below to create an account.</StyledParagraph>
                </View>
                <View style={{ flexDirection: 'row', gap: '16px', '@media (max-width: 767px)': { flexDirection: 'column' } }}>
                  <TextField label='First Name' name='firstName' value={signupForm.values.firstName} error={signupForm.errors.firstName} onChange={signupForm.handleChange} onValidate={signupForm.handleValidate} required />
                  <TextField label='Last Name' name='lastName' value={signupForm.values.lastName} error={signupForm.errors.lastName} onChange={signupForm.handleChange} onValidate={signupForm.handleValidate} required />
                </View>
                <TextField label='Email' name='email' value={signupForm.values.email} error={signupForm.errors.email} onChange={signupForm.handleChange} onValidate={signupForm.handleValidate} required />
                <StyledParagraph>By signing up for Bar Science, you agree to our <Link href='https://barscience.us/about/tos'>Terms of Service</Link> and <Link href='https://barscience.us/about/privacy-policy'>Privacy Policy</Link>.</StyledParagraph>
                {inviteError && <StyledParagraph style={{ color: Colors.error500 }}>{inviteError.message}</StyledParagraph>}
                <Button label='Sign Up' variant='primary' role='button' action={() => { }} type='submit' isFullWidth disabled={signupForm.hasError} loading={inviteIsLoading} style={{ marginTop: '16px' }} />
              </View>
            </Form>
          }
        </Card>
      </StandardPageWrapper>
    </>
  );
}