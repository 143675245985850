import { Button, Card, Colors, Icons, StyledDisplay, StyledHeading, StyledParagraph, StyledSubheading, View } from "@barscience/global-components";
import StandardPageWrapper from "../../components/layout/StandardPageWrapper";

export default function Home() {
  return (
    <>
      <StandardPageWrapper style={{ padding: '0', '@media (max-width: 767px)': { padding: '0' } }}>
        <View style={{ alignItems: 'center', gap: '100px', paddingBottom: '100px', width: '100%' }}>

          <View style={{ alignItems: 'center', background: 'linear-gradient(180deg, #FFF 23.96%, #F0FDF0 99.99%, #FFF 100%)', width: '100%' }}>
            <View style={{ alignItems: 'center', flexDirection: 'row', gap: '48px', justifyContent: 'space-between', maxWidth: '1800px', padding: '100px 140px', width: '100%', '@media (max-width: 1385px)': { flexDirection: 'column-reverse' }, '@media (max-width: 767px)': { padding: '40px 0px' } }}>
              <View style={{ gap: '24px', maxWidth: '100%', minWidth: 'min(526px, 100%)', padding: '24px', '@media (max-width: 1385px)': { alignItems: 'center' } }}>
                <StyledDisplay size='small' weight='bold' style={{ '@media (max-width: 1385px)': { textAlign: 'center' } }}>Elevate Your Bar and Restaurant Management</StyledDisplay>
                <StyledSubheading style={{ color: Colors.neutral700, '@media (max-width: 1385px)': { textAlign: 'center' } }} >Streamline operations, reduce waste, and boost profits with our powerful software suite designed exclusively for bars and restaurants.</StyledSubheading>
                <Button label='Explore Products' variant='primary' role='link' href='/products' rightIcon={Icons.ArrowRight} style={{ maxWidth: '', width: 'fit-content' }} />
              </View>
              <img src='illustrations/restaurant-team.svg' alt='A restaurant team' style={{ flexShrink: 1, maxWidth: 'min(600px, 90%)', minWidth: '300px', width: '100%' }} />
            </View>
          </View>

          <View style={{ alignItems: 'center', width: '100%' }}>
            <View style={{ alignItems: 'center', flexDirection: 'row', gap: '48px', justifyContent: 'space-between', maxWidth: '1800px', padding: '0px 140px', width: '100%', '@media (max-width: 1385px)': { flexDirection: 'column' }, '@media (max-width: 767px)': { padding: '40px 0px' } }}>
              <img src='illustrations/small-business.svg' alt='A small storefront held in a hand' style={{ flexShrink: 1, maxWidth: 'min(600px, 70%)', minWidth: '300px', width: '100%' }} />

              <View style={{ gap: '24px', maxWidth: '100%', minWidth: 'min(526px, 100%)', padding: '24px', '@media (max-width: 1385px)': { alignItems: 'center' } }}>
                <StyledHeading tag='h1' weight='bold' style={{ '@media (max-width: 1385px)': { textAlign: 'center' } }}>Built for Small Business</StyledHeading>
                <StyledParagraph size='large' style={{ color: Colors.neutral700, '@media (max-width: 1385px)': { textAlign: 'center' } }} >We understand the unique challenges faced by small businesses in the bar and restaurant industry. That's why we've designed Bar Science to be the ideal solution for small businesses, empowering you to achieve success.</StyledParagraph>
              </View>
            </View>
          </View>

          <View style={{ alignItems: 'center', backgroundColor: Colors.secondary50, width: '100%' }}>
            <View style={{ alignItems: 'center', flexDirection: 'row', gap: '48px', justifyContent: 'space-between', maxWidth: '1800px', padding: '40px 140px', width: '100%', '@media (max-width: 1385px)': { flexDirection: 'column-reverse' }, '@media (max-width: 767px)': { padding: '40px 0px' } }}>
              <View style={{ gap: '24px', maxWidth: '100%', minWidth: 'min(526px, 100%)', padding: '24px', '@media (max-width: 1385px)': { alignItems: 'center' } }}>
                <StyledHeading tag='h1' weight='bold' style={{ '@media (max-width: 1385px)': { textAlign: 'center' } }}>Simple and Affordable</StyledHeading>
                <StyledParagraph size='large' style={{ color: Colors.neutral700, '@media (max-width: 1385px)': { textAlign: 'center' } }} >Our products offer an intuitive and user-friendly interface that doesn't require extensive training or technical expertise. Our pricing plans are flexible and affordable, allowing you to access powerful tools without breaking the bank.</StyledParagraph>
              </View>

              <img src='illustrations/piggy-bank.svg' alt='A piggy bank' style={{ flexShrink: 1, maxWidth: 'min(600px, 70%)', minWidth: '300px', width: '100%' }} />
            </View>
          </View>

          <Card size='large' style={{ maxWidth: 'min(70%, 1000px)', padding: '48px' }}>
            <View style={{ alignItems: 'center', flexDirection: 'row', gap: '48px', '@media (max-width: 1530px)': { flexDirection: 'column' } }}>
              <View style={{ gap: '24px' }}>
                <StyledHeading tag='h1' weight='bold'>Take Control of Your Bar or Restaurant Today!</StyledHeading>
                <StyledParagraph size='large' style={{ color: Colors.neutral700 }}>Join Bar Science and unlock the tools you need to streamline operations, reduce costs, and maximize profitability.</StyledParagraph>
                <StyledParagraph size='large' style={{ color: Colors.neutral700 }}>Explore our products and start your free trial today!</StyledParagraph>
              </View>

              <Button label='Explore Products' variant='primary' role='link' href='/products' rightIcon={Icons.ArrowRight} style={{ maxWidth: '', width: 'fit-content' }} />
            </View>
          </Card>

        </View>
      </StandardPageWrapper>
    </>
  );
}